// Import the edliy_utils
import {
    makeResponsive,
    placeTitle,
    placeImage,
    placeInput,
    placeSlider,
    hoverMe,
    placeRec,
    hiddenPt,
    fixedPt,
    clearInputFields,
    dragMe,
    placeArrow,
    placeGravity,
    placeMiddleText,
    placeLine,
    placePoint,
    placeGlider,
    placeRuler,
    placeLeftText,
    placeRightText,
    placeSliderSwitch,
    placeCircle,
    placeLogo,
    placeShuffle,
    placeTest,
    placeUndo,
    placeRedo,
    placeBoldText,
    placeBoldLeftText,
    placeEraseLeft,
    placeStat,
    placeLabel,
    placeTapeHori,
    placeTapeVert,
    toggle,
    placeText
} from '../../../../common/edliy_utils-geometric';
const Boxes = {
  box1: function () {
  JXG.Options.board.minimizeReflow = 'none';
  var brd1 = JXG.JSXGraph.initBoard('jxgbox1',{boundingbox: [-10, 10, 10, -10], keepaspectratio: true, axis:false, ticks:{visible:false},
  grid:true, showCopyright:false, showNavigation:false,
  pan:{enabled:false}, zoom:{enabled:false}});
///////////////////////////////OPTIONS/////////////////////////////
JXG.Options.board.minimizeReflow = 'none';
JXG.Options.point.showInfoBox=false;
JXG.Options.point.highlight=false;
JXG.Options.image.highlight=false;
JXG.Options.text.highlight=false;
JXG.Options.text.fixed=true;
JXG.Options.curve.highlight=false;
JXG.Options.text.cssDefaultStyle='fontFamily:Oswald;'
////////////////////////////////LAYERING////////////////////////////////
brd1.options.layer['image'] =12;
brd1.options.layer['circle'] =13;
brd1.options.layer['chart'] =14;
brd1.options.layer['sector'] =15;
brd1.options.layer['line'] =15;
brd1.options.layer['point'] =20;
//////////////////////////////////////////////////////////////
makeResponsive(brd1);
placeTitle(brd1, 'Coordinates of a Point', 'Enter the X & Y Coordinates of Point P');
placeLogo(brd1);
///////////////////////////////////BUTTONS//////////////////////
var analytics =placeShuffle(brd1);
analytics.setLabel('Tap to shuffle point position')
analytics.label.setAttribute({visible:false, alignX:'middle', offset:[0, -15], CssStyle:'fontFamily:Oswald', fontSize:12});
analytics.on('over', function () {this.label.setAttribute({visible:true});});
analytics.on('out', function () {this.label.setAttribute({visible:false});});
/////////////////////////////////////////////////////////////////////////////////////
var erase =placeTest(brd1);
erase.setLabel('Tap to check your answer')
erase.label.setAttribute({visible:false, alignX:'middle', offset:[0, -15], CssStyle:'fontFamily:Oswald', fontSize:12});
erase.on('over', function () {this.label.setAttribute({visible:true});});
erase.on('out', function () {this.label.setAttribute({visible:false});});
///////////////////////////////////////////////////////////////////////////
var xt =placeBoldLeftText(brd1, -9, 6.5, 'x =');
//y.setAttribute({color:'red'});
var x =placeInput(brd1, -8, 6.5, "");
//m.setAttribute({cssStyle:'color:red'});
var yt =placeBoldLeftText(brd1, -9, 4.5, ' y = ');
//  x.setAttribute({color:'red'});
var y =placeInput(brd1, -8, 4.5, "");
var xp =3;
var yp =4;
///////////////////////////////////////////////////////////////////////////////////////
var pts = brd1.create('point', [()=>xp.valueOf(), ()=>yp.valueOf()], {name:'', highlight:false, size:function(){return 4*brd1.canvasHeight/800}, fillColor:'orange', strokeColor:'black', strokeWidth:1, visible:true, snapToGrid:true, shadow:true});
placeLabel(brd1, pts, 'P(x, y)', 15, 15);
var ques1 =placeImage(brd1, '/assets/query.svg', -5.8, 6.2, 0.6, 0);
var rght1 = placeImage(brd1, '/assets/check.svg', -5.8, 6.2, 0.6, 0);
rght1.setAttribute({visible:false});
var wrng1 = placeImage(brd1, '/assets/cross.svg', -5.8, 6.2, 0.6, 0);
wrng1.setAttribute({visible:false});
//////////////////////////////////////////////////////////////////
var ques2 =placeImage(brd1, '/assets/query.svg', -5.8, 4.2, 0.6, 0);
var rght2 = placeImage(brd1, '/assets/check.svg', -5.8, 4.2, 0.6, 0);
rght2.setAttribute({visible:false});
var wrng2 = placeImage(brd1, '/assets/cross.svg', -5.8, 4.2, 0.6, 0);
wrng2.setAttribute({visible:false});
//////////////////////////////////////////////////////
var i=1;
var xcord=brd1.create('segment', [[()=>pts.X(), ()=>pts.Y()], [0, ()=>pts.Y()]],{visible:()=>i==1, dash:1, strokeWidth:2, strokeColor:'black'});
var ycord=brd1.create('segment', [[()=>pts.X(), ()=>pts.Y()], [()=>pts.X(), 0]],{visible:()=>i==1, dash:1, strokeWidth:2, strokeColor:'black'});
////////////////
var side3 = placeTapeHori(brd1, [0.0,0.0],[()=>pts.X(), 0.0],'');
side3.setAttribute({visible:()=>i==1, point1:{visible:false},point2:{visible:false},  strokeWidth:1, strokeColor:'grey', label:{visible:()=>i==2, color:'blue'}});
var side2 = placeTapeVert(brd1, [0.0, 0.0], [0.0, ()=>pts.Y()],'');
side2.setAttribute({visible:()=>i==1, point1:{visible:false},point2:{visible:false}, strokeWidth:1, strokeColor:'grey', label:{rotate:90, visible:()=>i==2, color:'blue'}});
///////////////////////////////////////////////////////
var xAxis =placeText(brd1, ()=>pts.X()/2, ()=>pts.Y()+0.5, 16, ()=>(pts.X()).toFixed(2));
xAxis.setAttribute({color:'red', visible:()=>i==2});
var yAxis =placeText(brd1, ()=>pts.X()+0.5, ()=>pts.Y()/2, 16, ()=>(pts.Y()).toFixed(2));
yAxis.setAttribute({rotate:90, color:'red', visible:()=>i==2});
////////////////////////////////////////////////
erase.on('down', function(){
  if(1.*x.Value()==pts.X())
  {
    ques1.setAttribute({visible:false});
    rght1.setAttribute({visible:true});
    wrng1.setAttribute({visible:false});
  }
  else
  {
    ques1.setAttribute({visible:false});
    rght1.setAttribute({visible:false});
    wrng1.setAttribute({visible:true});
  }
  if(1.*y.Value()==pts.Y())
  {
    ques2.setAttribute({visible:false});
    rght2.setAttribute({visible:true});
    wrng2.setAttribute({visible:false});
  }
  else
  {
    ques2.setAttribute({visible:false});
    rght2.setAttribute({visible:false});
    wrng2.setAttribute({visible:true});
  }
});
analytics.on('down', function(){i=1;
            clearInputFields(brd1);
            xp= 1+ Math.round(10*(Math.random()-0.5));
            yp= Math.round(12*(Math.random()-0.5));
            ques1.setAttribute({visible:true});
            rght1.setAttribute({visible:false});
            wrng1.setAttribute({visible:false});
            ques2.setAttribute({visible:true});
            rght2.setAttribute({visible:false});
            wrng2.setAttribute({visible:false});
          });
brd1.create('axis', [[0,0],[0,4]],{highlight:false, strokeColor:'grey', strokeWidth:2, name:'', withLabel:true, label:{position:'urt',fontSize:function(){return 24*brd1.canvasHeight/800}, cssStyle:'fontFamily:Oswald;'}});
brd1.create('axis', [[0,0],[4,0]],{highlight:false, strokeColor:'grey', strokeWidth:2, name:'', withLabel:true, label:{position:'lrt', fontSize:function(){return 24*brd1.canvasHeight/800}, cssStyle:'fontFamily:Oswald;'}});
    },
}
export default Boxes;
