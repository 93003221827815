<template>
  <div>
    <div
      class="font-weight-light"
      :class="{'pa-5 headline': $vuetify.breakpoint.lgAndUp,'pa-2 body-2': $vuetify.breakpoint.mdOnly, 'pa-2 body-2': $vuetify.breakpoint.smAndDown}"
    >
      <h3 ref="ia">
        Coordinate System
      </h3>
      <div style="margin-top: 10px" />
      <p class="big mt-1">
        The goal of a coordinate system is a means of specifying the position of an object.
        For example, imagine a billiards table, and consider a ball on this table.
        Now, if someone asks you to specify the location of the ball, how would you do this?
        You could say &mdash; the ball is located in the lower half of the table.
        Or, it is near the left edge of the board.
        Or, the ball is located at the lower left corner of the table.
        None of these statements describe the position of the ball precisely. <br> <br>
        Now, if you say &mdash; the ball is 18 inches away from the left edge, it gives
        a better idea of the location of the ball but
        still does not fix the position of the ball. However, if you say the ball
        is located at 18 inches from the left edge and 12 inches from the bottom edge, it completely
        specifies the location of the ball on the table. You may have noticed that the ball on the table can be located
        by specifying its distances from two perpendicular lines &mdash; the left edge of the table, and the bottom edge of the paper.
        This notion forms the basis of coordinate geometry.
        <br> <br>
        The position of any object lying in a plane
        can be represented by its distance from two perpendicular axes (lines). These axes are called coordinate axes, and the distances from these two axes used in specifying the location of
        the object are called its coordinates.
        <br>
      </p>
      <v-layout justify-center class="my-3">
        <v-img src="/assets/billiards.svg"
               max-height="450px"
               max-width="450px"
               contain
        />
      </v-layout>
      <h3 ref="playgraph">
        Learn Interactively | Coordinates of a Point on the Cartesian Plane
      </h3>
      <div style="margin-top: 10px" />
      <h5> Session Objectives </h5>
      <p class="big mt-1">
        Through this visually interactive illustration, students will be able to
        assess their understanding of the Cartesian coordinates of a point. 
        The MagicGraph shows a point named P on the Cartesian plane. Your goal is to find the the two coordinates of the point on this plane.
      </p>
      <h5> Getting Started </h5>
      <p class="big mt-1">
        Enter your answers for the x and y coordinates in the blue boxes labeled as <b>x</b> and <b>y</b>, respectively.
        Once done, simply tap on the <i class="tester ma-1" /> icon to check your answer.
        To practice with a new point position, simply tap on the <i class="shuffler ma-1" /> icon to shuffle the point position.
      </p>
    </div>
    <div style="margin-top: 10px" />
    <v-responsive>
      <v-layout justify-center>
        <div id="jxgbox1" class="edliy-box-about" />
      </v-layout>
      <v-layout justify-center>
        <h5> Navigation Tip: Tap on <i class="shuffler ma-1" /> icon to shuffle. <br> Tap on <i class="tester ma-1" /> icon to check if the provided answer is correct or not.</h5>  
      </v-layout>
    </v-responsive>
  </div>
</template>
<script>
import Boxes from './Boxes.js'
export default {
  name: 'CoordinatesPoint',
  created: function () {
    this.$store.commit('navigation/resetState');
    let title = 'Coordinates of a Point';
    this.$store.commit('navigation/changeTitle', title);
    this.$store.commit('navigation/changeMenu', title);
    let newTopics = [
      {title: 'What is a Chemical Equation?',img:'/assets/number-1.svg', action: () => this.goto('def')},
      {title: 'Reactants and Products', img:'/assets/number-2.svg', action: () => this.goto('pg')},
      {title: 'Balanced versus unbalanced Equation',img:'/assets/number-3.svg', action: () => this.goto('pg')},
      {title: 'Balancing a Chemical Equation', img:'/assets/touch.svg', action: () => this.goto('pg')},
    ];
    this.$store.commit('navigation/replaceTopics', newTopics);
    let newshowhome = false;
    this.$store.commit('navigation/toggleshowhome', newshowhome);
    this.$store.commit('navigation/replaceMath', true);
    let newLeftArrow =true;
    this.$store.commit('navigation/replaceLeftArrow', newLeftArrow);
    let newModule=true;
    this.$store.commit('navigation/replaceModule', newModule);
  },
  mounted () {
    MathJax.Hub.Queue(["Typeset", MathJax.Hub]);
    Boxes.box1();
  },
  metaInfo() {
  return{ title: 'Coordinates of a Point',
          titleTemplate: '%s | Learn interactively',
          meta: [ {name: 'viewport', content: 'width=device-width, initial-scale=1'},
                  {name: 'description', content: 'Balancing a chemical equation.'}
                ]
        }
   },
}
</script>
